<template>
  <div class="row">
    <input-select
      v-model="filtro.SituacaoOrcamento"
      class="col-12 col-md-6"
      :label="$t('modulos.orcamentos.filtro.status')"
      :placeholder="$t('geral.inputs.selecione')"
      :options="opcoes.tipoSituacao"
      em-filtro
      clearable
      retornar-objeto
    />
    <input-text
      v-model="filtro.codigo"
      class="col-12 col-md-6"
      :label="$t('modulos.orcamentos.filtro.codigo')"
      em-filtro
    />
    <input-text
      v-model="filtro.cliente"
      class="col-12 col-md-6"
      :label="$t('modulos.orcamentos.filtro.nome_cliente')"
      em-filtro
    />
    <input-text
      v-model="filtro.ordemServico"
      class="col-12 col-md-6"
      :label="$t('modulos.orcamentos.filtro.ordem_servico')"
      em-filtro
    />
    <input-select
      v-model="filtro.tipoData"
      class="col-12 col-md-6"
      :label="$t('modulos.orcamentos.filtro.tipo_data')"
      :placeholder="$t('geral.inputs.selecione')"
      :options="opcoes.tipoData"
      em-filtro
      clearable
      retornar-objeto
    />
    <input-date
      v-model="de"
      type="month"
      mask="##/####"
      class="col-12 col-md-6"
      :label="$t('modulos.orcamentos.filtro.de')"
      em-filtro
      pode-limpar-data
      nudge="-175px"
      transition="''"
    />
    <input-date
      v-model="ate"
      type="month"
      mask="##/####"
      class="col-12 col-md-6"
      :label="$t('modulos.orcamentos.filtro.ate')"
      em-filtro
      pode-limpar-data
      nudge="-175px"
      transition="''"
    />
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import { enumOrcamentoIcons } from '@enums/iconsOrcamento';
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      de: null,
      ate: null,
      opcoes: {
        tipoData: helpers.TipoDataEnum,
        tipoSituacao: [],
      },
    };
  },
  watch: {
    de() {
      this.validaDataDe();
    },
    ate() {
      this.validaDataAte();
    },
    'filtro.tipoData'() {
      this.validaDataDe();
      this.validaDataAte();
    },
  },
  mounted() {
    this.opcoes.tipoSituacao = enumOrcamentoIcons.map((situacao) => {
      return { value: situacao.value, text: situacao.message };
    });
  },
  methods: {
    validaDataDe() {
      if (this.filtro.tipoData) {
        if (this.filtro.tipoData?.value == 'dataEnvio') {
          this.filtro.dataEnvioInicio = this.de;
          this.filtro.dataVencimentoInicio = null;
          this.filtro.dataEmissaoInicio = null;
        }
        if (this.filtro.tipoData?.value == 'dataVencimento') {
          this.filtro.dataEnvioInicio = null;
          this.filtro.dataVencimentoInicio = this.de;
          this.filtro.dataEmissaoInicio = null;
        }
        if (this.filtro.tipoData?.value == 'dataEmissao') {
          this.filtro.dataEnvioInicio = null;
          this.filtro.dataVencimentoInicio = null;
          this.filtro.dataEmissaoInicio = this.de;
        }
      }
    },
    validaDataAte() {
      if (this.filtro.tipoData) {
        if (this.filtro.tipoData?.value == 'dataEnvio') {
          this.filtro.dataEnvioFim = this.ate;
          this.filtro.dataVencimentoFim = null;
          this.filtro.dataEmissaoFim = null;
        }
        if (this.filtro.tipoData?.value == 'dataVencimento') {
          this.filtro.dataEnvioFim = null;
          this.filtro.dataVencimentoFim = this.ate;
          this.filtro.dataEmissaoFim = null;
        }
        if (this.filtro.tipoData?.value == 'dataEmissao') {
          this.filtro.dataEnvioFim = null;
          this.filtro.dataVencimentoFim = null;
          this.filtro.dataEmissaoFim = this.ate;
        }
      }
    },
  },
};
</script>
