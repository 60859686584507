<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.orcamentos.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.orcamentos.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('Orcamento', 'Inserir')"
      :sem-filtro="!buscarPermissao('Orcamento', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <div class="d-flex">
      <botao-padrao
        v-if="podeLiberarOrcamentoLote"
        class="my-2"
        @click="liberarOrcamentoLote()"
      >
        {{ $t('geral.botoes.liberar_orcamentos_lote') }}
      </botao-padrao>
      <botao-padrao
        v-if="podeEnviarEmailLote"
        class="my-2"
        @click="enviarEmailLote()"
      >
        {{ $t('geral.botoes.enviar_email_lote') }}
      </botao-padrao>
      <botao-padrao
        v-if="podeExcluir && buscarPermissao('Orcamento', 'Deletar')"
        class="my-2 ml-2"
        outlined
        color="danger"
        @click="excluirRegistros('excluir')"
      >
        <v-icon>$mdiTrashCanOutline</v-icon>
        {{ $t('geral.botoes.excluir') }}
      </botao-padrao>
    </div>

    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      :mostrar-acoes="false"
      filtros-com-colunas
      :mostrar-seletor="buscarPermissao('Orcamento', 'Deletar')"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)"
      @filtrarGlobal="listarRegistros"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:valor="{ slotProps }">
        <div class="alinhar-direita">
          {{ formatacaoMoeda(slotProps.data.valor) }}
        </div>
      </template>
      <template v-slot:status="{ slotProps }">
        <div class="d-flex">
          <dropdown-padrao
            v-if="mostraDropDown"
            style="width: 40px"
            text
            color="secondary"
          >
            <template #botao>
              <v-icon> $dotsVertical </v-icon>
            </template>
            <v-list-item-group style="overflow-y: auto">
              <v-list-item
                v-if="buscarPermissao('Orcamento', 'Editar')"
                class="d-flex min-height-drop-item"
                :disabled="naoPodeEditar(slotProps.data.status)"
                @click="abrirEditar(slotProps.data)"
              >
                {{ $t('geral.botoes.editar') }}
              </v-list-item>
              <v-list-item
                v-if="buscarPermissao('Orcamento', 'EditarComoCliente')"
                class="d-flex min-height-drop-item"
                :disabled="!!slotProps.data.ordemServico"
                @click="abrirEditarCliente(slotProps.data)"
              >
                Editar como Cliente
              </v-list-item>
              <v-divider />
              <v-list-item
                v-if="buscarPermissao('Orcamento', 'Visualizar')"
                class="d-flex min-height-drop-item"
                @click="abrirVisualizacao(slotProps.data)"
              >
                {{ $t('geral.botoes.visualizar') }}
              </v-list-item>
              <v-list-item
                v-if="buscarPermissao('Orcamento', 'ListarComentario')"
                class="d-flex min-height-drop-item"
                @click="abrirComentar(slotProps.data)"
              >
                {{ $t('modulos.orcamentos.botoes.comentar') }}
              </v-list-item>
              <v-divider />
              <dropdown-padrao
                submenu
                color="secondary"
              >
                <template #botao>
                  <v-list-item-group style="font-weight: 400">
                    {{ $t('geral.botoes.alterar_situacao') }}
                  </v-list-item-group>
                </template>
                <v-list-item-group style="overflow-y: auto">
                  <v-list-item
                    v-if="buscarPermissao('Orcamento', 'Redefinir')"
                    class="d-flex"
                    :disabled="
                      slotProps.data.status !== 'Aprovado' &&
                        slotProps.data.status !== 'Reprovado'
                    "
                    @click="redefinirOrcamento(slotProps.data)"
                  >
                    {{ $t('geral.botoes.redefinir_orcamento') }}
                  </v-list-item>
                  <v-divider />
                  <v-list-item
                    v-if="buscarPermissao('Orcamento', 'Liberar')"
                    :disabled="slotProps.data.status !== 'AguardandoLiberacao'"
                    class="d-flex"
                    @click="liberarOrcamento(slotProps.data)"
                  >
                    {{ $t('geral.botoes.liberar_orcamento') }}
                  </v-list-item>
                  <v-divider />
                  <v-list-item
                    v-if="buscarPermissao('Orcamento', 'Aprovar')"
                    class="d-flex"
                    :disabled="slotProps.data.status !== 'AguardandoAprovacao'"
                    @click="aprovarOrcamento(slotProps.data)"
                  >
                    {{ $t('geral.botoes.aprovar_orcamento') }}
                  </v-list-item>
                  <v-divider />
                  <v-list-item
                    v-if="buscarPermissao('Orcamento', 'Aprovar')"
                    class="d-flex"
                    :disabled="slotProps.data.status !== 'AguardandoAprovacao'"
                    @click="reprovarOrcamento(slotProps.data)"
                  >
                    {{ $t('geral.botoes.reprovar_orcamento') }}
                  </v-list-item>
                </v-list-item-group>
              </dropdown-padrao>
              <v-divider />
              <dropdown-padrao
                submenu
                color="secondary"
              >
                <template #botao>
                  <v-list-item-group style="font-weight: 400">
                    {{ $t('geral.botoes.enviar_cliente') }}
                  </v-list-item-group>
                </template>
                <v-list-item-group style="overflow-y: auto">
                  <v-list-item
                    v-if="buscarPermissao('Orcamento', 'EnviarAoCliente')"
                    class="d-flex"
                    :disabled="
                      slotProps.data.status !== 'AguardandoEnvioCliente'
                    "
                    @click="enviarEmailCliente(slotProps.data.id)"
                  >
                    {{ $t('geral.botoes.enviar_email') }}
                  </v-list-item>
                  <v-divider />
                  <v-list-item
                    v-if="buscarPermissao('Orcamento', 'EnviarAoCliente')"
                    class="d-flex"
                    :disabled="slotProps.data.status !== 'AguardandoAprovacao'"
                    @click="reenviarEmailCliente(slotProps.data.id)"
                  >
                    {{ $t('geral.botoes.reenviar_email') }}
                  </v-list-item>
                  <v-divider />
                  <v-list-item
                    v-if="buscarPermissao('Orcamento', 'EnviarAoCliente')"
                    :disabled="
                      slotProps.data.status !== 'AguardandoEnvioCliente'
                    "
                    class="d-flex"
                    @click="marcarComoEnviado(slotProps.data.id)"
                  >
                    {{ $t('geral.botoes.marcar_como_enviado') }}
                  </v-list-item>
                </v-list-item-group>
              </dropdown-padrao>
              <v-divider />
              <v-list-item
                v-if="buscarPermissao('Orcamento', 'Duplicar')"
                class="d-flex min-height-drop-item"
                @click="duplicarOrcamento(slotProps.data.id)"
              >
                {{ $t('geral.botoes.duplicar') }}
              </v-list-item>
              <v-list-item
                v-if="buscarPermissao('Orcamento', 'Imprimir')"
                class="d-flex"
                @click="gerarPDF(slotProps.data.id)"
              >
                {{ $t('geral.botoes.imprimir') }}
              </v-list-item>
              <v-divider />
              <v-list-item
                v-if="
                  verificarOrcamento(slotProps.data) &&
                    buscarPermissao('Orcamento', 'Visualizar')
                "
                class="d-flex min-height-drop-item"
                @click="abrirVisualizarRevisoes(slotProps.data)"
              >
                {{ $t('geral.botoes.visualizar_revisoes') }}
              </v-list-item>
              <v-divider />
              <v-list-item
                v-if="buscarPermissao('Orcamento', 'Visualizar')"
                class="d-flex min-height-drop-item"
                @click="
                  abrirModalContatosCliente(
                    slotProps.data.participante?.id
                  )
                "
              >
                {{ $t('geral.botoes.visualizar_contatos') }}
              </v-list-item>
            </v-list-item-group>
          </dropdown-padrao>
          <div class="d-flex">
            <icone-padrao
              :icone="buscaStatusIcon(slotProps.data.status)"
              :tooltip="buscaStatusMensagemTooltip(slotProps.data.status)"
            />
          </div>
        </div>
      </template>
      <template v-slot:codigo="{ slotProps }">
        <div class="d-flex">
          <div>
            <span style="align-self: center">
              {{ slotProps.data.codigo }}
            </span>
          </div>
        </div>
      </template>
      <template v-slot:vencido="{ slotProps }">
        <span
          v-if="slotProps.data.vencido"
          class="tag-vencido"
        >{{
          $t('modulos.orcamentos.tabela.vencido')
        }}</span>
        <span
          v-if="slotProps.data.comentario"
          class="ml-2 tag-comentario"
        >{{
          $t('modulos.orcamentos.tabela.comentario')
        }}</span>
      </template>
    </tabela-padrao-prime-vue>
    <revisao-modal
      ref="modal-revisao"
      @salvar-novo="buscarIdOrigem"
    />
    <comentario-modal
      ref="modal-comentario"
      l
      @salvar-novo="comentarioOrcamento"
    />
    <revisao-lista-modal
      ref="modal-lista-revisao"
      @salvar-novo="buscarIdOrigem"
    />
    <contatos-cliente-modal ref="modal-contatos-cliente" />
    <reenvio-email-modal
      ref="modal-reenviar-email"
      :tipo-email="'orcamento'"
    />
    <modal-visualizacao-relatorio
      v-if="buscarPermissao('Orcamento', 'Listar')"
      ref="modal-pdf"
      :titulo="$t('modulos.orcamentos.titulos.impressao')"
    />
  </div>
</template>
<script>
import ComentarioService from '@common/services/cadastros/ComentarioService';
import OrcamentosService from '@common/services/cadastros/OrcamentosService';
import helpers from '@common/utils/helpers';
import ReenvioEmailModal from '@components/fragments/Modals/ReenvioEmailModal';
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import { ModalVisualizacaoRelatorio } from '@components/misc';
import { enumOrcamentoIcons } from '@enums/iconsOrcamento';
import ComentarioModal from './components/modais/ComentarioModal.vue';
import ContatosClienteModal from './components/modais/ContatosClienteModal.vue';
import RevisaoListaModal from './components/modais/RevisaoListaModal.vue';
import RevisaoModal from './components/modais/RevisaoModal.vue';
import Filtro from './filtro';

export default {
  components: {
    CabecalhoPagina,
    RevisaoModal,
    RevisaoListaModal,
    ComentarioModal,
    ContatosClienteModal,
    Filtro,
    ReenvioEmailModal,
    ModalVisualizacaoRelatorio,
  },
  data() {
    return {
      keyAtualiza: 1,
      mostraDropDown: true,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'status',
            text: this.$t('modulos.orcamentos.tabela.situacao'),
            sortable: true,
          },
          {
            value: 'codigo',
            text: this.$t('modulos.orcamentos.tabela.codigo'),
            sortable: true,
          },
          {
            value: 'participante.apelido',
            text: this.$t('modulos.orcamentos.tabela.cliente'),
            sortable: true,
            width: '300px',
          },
          {
            value: 'ordemServico.codigo',
            text: this.$t('modulos.orcamentos.tabela.ordem_servico'),
            sortable: true,
          },
          {
            value: 'valor',
            text: this.$t('modulos.orcamentos.tabela.valor'),
            sortable: true,
          },
          {
            value: 'dataEmissao',
            text: this.$t('modulos.orcamentos.tabela.data_emissao'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            value: 'dataVencimento',
            text: this.$t('modulos.orcamentos.tabela.data_vencimento'),
            sortable: false,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            value: 'dataEnvio',
            text: this.$t('modulos.orcamentos.tabela.data_envio'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            value: 'vencido',
            text: this.$t('modulos.orcamentos.tabela.tag'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      tipoJustificativa: '',
      colunaOrdenada: '',
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
    podeLiberarOrcamentoLote: function () {
      return this.validacaoBotaoLote(4);
    },
    podeEnviarEmailLote: function () {
      return this.validacaoBotaoLote(5);
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'Orcamento', 'Listar');
    const filtros = helpers.buscarStateFiltros(this);
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.orcamentos.titulos.listagem')
    );
    this.colunaOrdenada = '-DataEmissao';
    this.listarRegistros();
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    reenviarEmailCliente(id) {
      this.dropDownOculta();
      this.$refs['modal-reenviar-email'].abrirModal(id);
    },
    retornaCodigosOrcamentos() {
      let codigos = this.tabela.selecionados
        .map((item) => {
          return `${item.codigo} -`;
        })
        .join(' ');
      return codigos.substring(0, codigos.length - 1);
    },
    liberarOrcamentoLote() {
      let codigos = '';
      codigos = this.retornaCodigosOrcamentos();
      this.confirmarLiberarOrcamentoLote(codigos).then(() => {
        let ids = [];
        this.tabela.selecionados.map((item) => {
          ids.push(item.id);
        });

        this.alterarStatusOrcamentoLote(ids, 5);
      });
    },
    enviarEmailLote() {
      let codigos = '';
      codigos = this.retornaCodigosOrcamentos();
      this.confirmarEnviarEmailLote(codigos).then(() => {
        let ids = [];
        this.tabela.selecionados.map((item) => {
          ids.push(item.id);
        });

        this.alterarStatusOrcamentoLote(ids, 1, true);
      });
    },
    alterarStatusOrcamentoLote(ids, proximoStatus, enviarEmail = false) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      let params = {
        ids: ids,
        statusOrcamento: proximoStatus,
        enviaEmail: enviarEmail,
        orcamentosItens: null,
        comentario: null,
      };
      OrcamentosService.alteraStatusOrcamentoLista(params).finally(() => {
        this.$store.dispatch('Layout/terminarCarregamento');
        this.listarRegistros(this.tabela.paginaAtual, this.tabela.porPagina);
        this.tabela.selecionados = [];
        this.keyAtualiza++;
      });
    },
    validacaoBotaoLote(status) {
      let liberaBotao = false;
      if (this.tabela.selecionados.length > 1) {
        liberaBotao = true;
        this.tabela.selecionados.map((item) => {
          if (item.status !== status) {
            liberaBotao = false;
          }
        });
      }
      return liberaBotao;
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.porPagina = porPagina;
      helpers.salvarStateFiltros(this, {
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
        sort: this.colunaOrdenada,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      OrcamentosService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .catch(() => {
          this.toastErro(this.$t(`geral.erros.erroGenerico`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: function (tipoJustificativa) {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.map((item) => {
          if (item.id) {
            if (item.codigo.includes('R'))
              return this.abrirComentarioOrcamento(item, tipoJustificativa);
            return this.excluir(item);
          }
        });
      });
    },
    excluir(item) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      OrcamentosService.excluir(item.id)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.orcamentos.exclusao_sucesso`));
          this.tabela.selecionados = [];
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.listarRegistros(this.tabela.paginaAtual, this.tabela.porPagina);
          this.tabela.selecionados = [];
        });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'orcamentos-novo' });
    },
    abrirEditar: function (item) {
      if (item.status == 'AguardandoAprovacao') {
        this.abrirRevisaoOrcamento(item);
      } else {
        this.$router.push({
          name: 'orcamentos-editar',
          params: { id: item.id },
        });
      }
    },
    abrirEditarCliente: function (item) {
      this.$router.push({
        name: 'orcamentos-editar-cliente',
        params: { id: item.id },
      });
    },
    abrirVisualizacao: function (item) {
      this.$router.push({
        name: 'orcamentos-visualizacao',
        params: { id: item.id, visualizacao: true },
      });
    },
    abrirComentar: function (item) {
      this.$router.push({
        name: 'orcamentos-editar',
        params: { id: item.id },
        query: { comentar: true },
      });
    },
    buscarIdOrigem: function (id, tipoJustificativa) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      OrcamentosService.buscar(id)
        .then((res) => {
          let resultado = res.data;
          this.abrirComentarioOrcamento(resultado, tipoJustificativa);
        })
        .catch(() => {
          this.toastErro(
            this.$t('modulos.orcamentos.erros.orcamento_invalido')
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    abrirRevisaoOrcamento: function (item) {
      this.$refs['modal-revisao'].abrirModal(item);
    },
    revisaoOrcamento: function (form) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      OrcamentosService.revisaoOrcamento(form.id)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.orcamentos.revisao_sucesso`));
          this.listarRegistros();
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    abrirComentarioOrcamento: function (form, tipoJustificativa) {
      this.$refs['modal-comentario'].abrirModal(form, tipoJustificativa);
      this.tabela.selecionados = [];
    },
    comentarioOrcamento: function ({ form, formRevisao, tipoJustificativa }) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ComentarioService.salvar(form)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.orcamentos.justificativa_sucesso`)
          );
          if (!tipoJustificativa) {
            this.revisaoOrcamento(formRevisao);
          } else {
            this.excluir(formRevisao);
          }
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    redefinirOrcamento(item) {
      this.confirmarRedefinirOrcamento().then(() => {
        this.alterarStatusOrcamento(item.id, 4);
      });
    },
    liberarOrcamento(item) {
      this.confirmarLiberarOrcamento(item.codigoItem).then(() => {
        this.alterarStatusOrcamento(item.id, 5);
      });
    },
    enviarEmailCliente(id) {
      this.alterarStatusOrcamento(id, 1, true);
    },
    dropDownOculta() {
      this.mostraDropDown = false;
      setTimeout(() => {
        this.mostraDropDown = true;
      }, 300);
    },
    alterarStatusOrcamento(id, proximoStatus, enviarEmail = false) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      OrcamentosService.alteraStatusOrcamento(
        id,
        proximoStatus,
        null,
        enviarEmail
      ).finally(() => {
        this.$store.dispatch('Layout/terminarCarregamento');
        this.listarRegistros(this.tabela.paginaAtual, this.tabela.porPagina);
        this.keyAtualiza++;
      });
    },
    aprovarOrcamento(item) {
      let valorFormatado = item.valor.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        style: 'currency',
        currency: 'BRL',
      });
      this.confirmarAprovarOrcamento(item.codigo, valorFormatado).then(() => {
        this.redirecionaOrcamentoAprovar(item, true);
      });
    },
    reprovarOrcamento(item) {
      this.confirmarReprovarOrcamento().then(() => {
        this.redirecionaOrcamentoAprovar(item, false);
      });
    },
    redirecionaOrcamentoAprovar(item, aprovarOuReprovar) {
      const aprovarReprovar = aprovarOuReprovar ? 'aprovar' : 'reprovar';
      this.$router.push({
        name: 'orcamentos-aprovar',
        params: { id: item.id },
        query: { aprovarOuReprovar: aprovarReprovar },
      });
    },
    marcarComoEnviado(id) {
      let mensagem;
      if (
        !this.tabela.selecionados.length ||
        this.tabela.selecionados.length == 1
      ) {
        mensagem = this.$t(
          `geral.alertas.marcar_como_enviado.mensagem_selecionado`
        );
      } else
        mensagem = this.$t(
          `geral.alertas.marcar_como_enviado.mensagem_selecionados`
        );

      this.confirmarMarcarComoEnviado(mensagem).then(() => {
        this.alterarStatusOrcamento(id, 1, false);
      });
    },
    buscaStatusIcon(status) {
      let icon = null;
      enumOrcamentoIcons.forEach((item) => {
        if (item.value === status) icon = item.icon;
      });
      return this.$vuetify.icons.values[icon];
    },
    buscaStatusMensagemTooltip(status) {
      let message = null;
      enumOrcamentoIcons.forEach((item) => {
        if (item.value === status) message = item.message;
      });
      return message;
    },
    duplicarOrcamento(id) {
      this.confirmarDuplicarOrcamento().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        OrcamentosService.duplicarOrcamento(id)
          .catch(() => {
            this.toastErro(this.$t(`geral.erros.erroGenerico`));
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
            this.listarRegistros(
              this.tabela.paginaAtual,
              this.tabela.porPagina
            );
          });
      });
    },
    abrirVisualizarRevisoes: function (item) {
      this.$refs['modal-lista-revisao'].abrirModal(item);
    },
    abrirModalContatosCliente: function (id) {
      this.$refs['modal-contatos-cliente'].abrirModal(id);
    },
    verificarOrcamento: function (item) {
      return item.codigo?.includes('R') ? false : true;
    },
    formatacaoMoeda(valor) {
      if (valor) return helpers.formatarMoeda(valor);
    },
    gerarPDF: function (id) {
      this.$refs['modal-pdf'].abrirModal('orcamento', [
        { simbolo: 'id', valor: id },
      ]);
    },
    naoPodeEditar: function (status) {
      const naoPodeEditar = ['AguardandoAprovacao','AprovadoParcialmente', 'Aprovado', 'Reprovado'];
      return naoPodeEditar.includes(status);
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>
<style>
.min-height-drop-item {
  min-height: 35px !important;
}
.alinhar-direita {
  display: flex;
  justify-content: right;
}
</style>
