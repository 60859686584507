<template>
  <modal-padrao
    ref="modal-comentario"
    :titulo="tituloModal"
    max-width="80%"
    :ok-desabilitado="!valido"
    :titulo-ok="$t('geral.botoes.confirmar')"
    @ok="confirmacaoSalvar"
  >
    <p>{{ mensagemModal }}</p>
    <v-form ref="form" class="row">
      <input-textarea
        v-model="form.mensagem"
        class="col-12 col-md-12"
        :label="
          $t('modulos.orcamentos.tabela.modal_comentario.form.justificativa')
        "
        :placeholder="
          $t('modulos.orcamentos.tabela.modal_comentario.form.justificativa')
        "
        :max="2000"
        obrigatorio
      />
    </v-form>
  </modal-padrao>
</template>
<script>
import TipoComentarioOrcamentoService from '@common/services/cadastros/TipoComentarioOrcamentoService';
export default {
  name: 'ComentarioOrcamentoModal',
  data() {
    return {
      valido: null,
      form: {},
      formReferencia: {},
      id: null,
      idOrigen: null,
      loadingTipoComentario: false,
      disabled: false,
      tipoJustificativa: '',
      formRevisao: {},
      opcoes: {
        tipoComentario: [],
      },
    };
  },
  computed: {
    tituloModal: function () {
      if (!this.tipoJustificativa)
        return this.$t(
          'modulos.orcamentos.tabela.modal_comentario.titulo_nova'
        );
      return this.$t(
        'modulos.orcamentos.tabela.modal_comentario.titulo_excluir'
      );
    },
    mensagemModal: function () {
      if (!this.tipoJustificativa)
        return this.$t(
          'modulos.orcamentos.tabela.modal_comentario.mensagem_nova'
        );
      return this.$t(
        'modulos.orcamentos.tabela.modal_comentario.mensagem_excluir'
      );
    },
  },
  watch: {
    idOrigen: function () {
      if (!this.idOrigen) this.disabled = true;
    },
    form: {
      handler() {
        if (this.$refs.form) this.valido = this.$refs.form.validate();
      },
      deep: true,
    },
  },
  methods: {
    iniciarForm() {
      this.form = {
        ...this.form,
        mensagem: '',
        tipoComentario: 2,
      };
    },
    abrirModal: function (form, tipoJustificativa) {
      this.formRevisao = form;
      if (form.orcamentoOrigemId) this.id = form.orcamentoOrigemId;
      this.id = form.id;
      this.tipoJustificativa = tipoJustificativa;

      this.iniciarForm();
      this.listarTipoComentarioOrcamento();
      this.$refs['modal-comentario'].abrirModal();
      if (this.$refs.form) this.valido = this.$refs.form.resetValidation();
    },
    salvar: function () {
      if (!this.$refs.form.validate()) return;
      this.form = {
        ...this.form,
        orcamentoId: this.id,
        usuarioCriacaoId: localStorage.getItem('usuarioId'),
      };
      this.$emit('salvar-novo', {
        form: this.form,
        formRevisao: this.formRevisao,
        tipoJustificativa: this.tipoJustificativa,
      });
      this.$refs['modal-comentario'].fecharModal();
    },
    confirmacaoSalvar: function () {
      this.salvar();
    },
    listarTipoComentarioOrcamento: function (form) {
      this.loadingTipoComentario = true;
      TipoComentarioOrcamentoService.listar(form)
        .then((res) => {
          let tipoComentarioLista = res.data.items;
          tipoComentarioLista.map((item) => {
            this.opcoes.tipoComentario.push({
              text: item.nome,
              value: item.id,
            });
          });
        })
        .finally(() => {
          this.loadingTipoComentario = false;
        });
    },
  },
};
</script>
