<template>
  <div>
    <modal-padrao
      ref="modal-lista-revisao"
      :titulo="$t('modulos.orcamentos.tabela.modal_list_revisao.titulo')"
      max-width="80%"
      :mostra-botao-salvar="false"
      :titulo-cancelar="$t('geral.botoes.fechar')"
      @ok="fecharModal"
    >
      <div class="d-flex align-center justify-space-between">
        <botao-padrao
          v-if="podeExcluir && buscarPermissao('Orcamento', 'ExcluirRevisao')"
          class="my-2"
          outlined
          color="danger"
          @click="excluirRegistros"
        >
          <v-icon>$mdiTrashCanOutline</v-icon>
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
        <div class="d-flex">
          <botao-padrao
            v-if="podeExcluir"
            class="my-2 mr-2"
            @click="abrirOrcamento"
          >
            {{ $t('geral.botoes.visualizar_orcamento') }}
          </botao-padrao>
          <botao-padrao
            v-if="buscarPermissao('Orcamento', 'CriarRevisao')"
            class="my-2"
            @click="adicionarRevisaoOrcamento"
          >
            {{ $t('geral.botoes.nova_revisao') }}
          </botao-padrao>
        </div>
      </div>

      <tabela-padrao
        v-model="tabela.selecionados"
        :dados="tabela.dados"
        class="mt-2"
        :colunas="tabela.colunas"
        :por-pagina="tabela.porPagina"
        :quantidade-paginas="tabela.quantidadePaginas"
        selecionar-apenas-um
        @paginar="listarRegistros"
      />
    </modal-padrao>
  </div>
</template>
<script>
import OrcamentosService from '@common/services/cadastros/OrcamentosService';
import helpers from '@common/utils/helpers';
export default {
  data() {
    return {
      item: null,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'codigo',
            text: this.$t(
              'modulos.orcamentos.tabela.modal_list_revisao.tabela.codigo'
            ),
            sortable: true,
          },
          {
            value: 'dataEmissao',
            text: this.$t(
              'modulos.orcamentos.tabela.modal_list_revisao.tabela.data_emissao'
            ),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            value: 'participanteCliente.nome',
            text: this.$t(
              'modulos.orcamentos.tabela.modal_list_revisao.tabela.usuario'
            ),
            sortable: true,
          },
          {
            value: 'valor',
            text: this.$t(
              'modulos.orcamentos.tabela.modal_list_revisao.tabela.valor'
            ),
            sortable: true,
            formatter: (v) => helpers.formatarMoeda(v),
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      id: null,
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  mounted() {},
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    abrirModal: function (item) {
      this.id = item.id;
      this.item = item;
      this.tabela.selecionados = [];

      this.listarRegistros();
      this.$refs['modal-lista-revisao'].abrirModal();
    },
    fecharModal: function () {
      this.$refs['modal-lista-revisao'].fecharModal();
    },
    listarRegistros: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        id: this.id,
        page: paginaAtual,
        amountPerPage: porPagina,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      OrcamentosService.buscarRevisoes(this.id, parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    abrirOrcamento: function () {
      this.$router.push({
        name: 'orcamentos-editar',
        params: { id: this.tabela.selecionados[0].id },
      });
    },
    adicionarRevisaoOrcamento: function () {
      this.$emit('salvar-novo', this.id);
      this.fecharModal();
    },
    excluirRegistros: function () {
      this.$emit('salvar-novo', this.tabela.selecionados[0], 'excluir');
      this.fecharModal();
    },
  },
};
</script>
