<template>
  <modal-padrao
    ref="modal-contatos-cliente"
    :titulo="$t('modulos.orcamentos.contatos_cliente')"
    max-width="80%"
    :mostra-botao-salvar="false"
  >
    <tabela-padrao
      :mostrar-checkbox="false"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
    />
  </modal-padrao>
</template>
<script>
import ParticipanteService from '@common/services/cadastros/ParticipanteService';
export default {
  data() {
    return {
      idOrcamento: null,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('modulos.orcamentos.tabela.nome'),
            sortable: true,
          },
          {
            value: 'telefone',
            text: this.$t('modulos.orcamentos.tabela.telefone'),
            sortable: true,
            width: '200px',
          },
          {
            value: 'email',
            text: this.$t('modulos.orcamentos.tabela.email'),
            sortable: true,
          },
          {
            value: 'setor',
            text: this.$t('modulos.orcamentos.tabela.setor'),
            sortable: true,
          },
          {
            value: 'cargo',
            text: this.$t('modulos.orcamentos.tabela.cargo'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  methods: {
    abrirModal: function (id) {
      this.idOrcamento = id;
      this.$refs['modal-contatos-cliente'].abrirModal();
      this.buscaContatosCliente(id);
    },
    fecharModal() {
      this.$refs['modal-contatos-cliente'].fecharModal();
    },
    buscaContatosCliente: function (id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      let params = {
        id,
      };
      ParticipanteService.listarContatosCliente(id, params)
        .then((res) => {
          this.tabela.dados = res.data;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = 1;
          this.tabela.porPagina = 10;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
