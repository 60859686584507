export const enumOrcamentoIcons = [
  {
    value: 'AguardandoAprovacao',
    message: 'Aguardando Aprovação',
    icon: 'fileClockOutline',
  },
  {
    value: 'Aprovado',
    message: 'Aprovado',
    icon: 'fileCheckOutline',
  },
  {
    value: 'Reprovado',
    message: 'Reprovado',
    icon: 'fileDocumentRemoveOutline',
  },
  {
    value: 'AguardandoLiberacao',
    message: 'Aguardando Liberação',
    icon: 'fileDocumentArrowRight',
  },
  {
    value: 'AguardandoEnvioCliente',
    message: 'Aguardando Envio para o Cliente',
    icon: 'accountClockOutline',
  },
  {
    value: 'EmElaboracao',
    message: 'Em Elaboração',
    icon: 'fileDocumentEditOutline',
  },
  {
    value: 'VinculadoOSExterna',
    message: 'Vinculado com Ordem de Serviços Externa',
    icon: 'fileArrowLeftRight',
  },
  {
    value: 'AguardandoValidacaoKeL',
    message: 'Aguardando Validação K&L',
    icon: 'fileClockOutline',
  },
  {
 value: 'AprovadoParcialmente',
    message: 'Aprovado Parcialmente',
    icon: 'mdiFileCheck',
  }
];
