<template>
  <modal-padrao
    ref="modal-revisao"
    :titulo="$t('modulos.orcamentos.tabela.modal_add_revisao.titulo')"
    max-width="80%"
    :titulo-ok="$t('geral.botoes.sim')"
    :titulo-cancelar="$t('geral.botoes.nao')"
    @ok="confirmacaoSalvar"
  >
    <p>
      {{ $t('modulos.orcamentos.tabela.modal_add_revisao.mensagem') }}
    </p>
  </modal-padrao>
</template>
<script>
export default {
  data() {
    return {
      item: null,
    };
  },
  methods: {
    abrirModal: function (item) {
      this.item = item.id;
      this.$refs['modal-revisao'].abrirModal();
    },
    salvar: function () {
      this.$emit('salvar-novo', this.item);
      this.$refs['modal-revisao'].fecharModal();
    },
    confirmacaoSalvar: function () {
      this.salvar();
    },
  },
};
</script>
